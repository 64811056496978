import React from 'react'
import {
  PhoneInput as PhoneInpuBuffet,
  defaultStrings,
  PhoneNumber
} from '@toasttab/buffet-pui-phone-input'
import { defaultStrings as defaultCountryStrings } from '@toasttab/buffet-pui-country-utilities'

type Params = {
  phoneInput: string
  setPhoneInput: (phone: string) => void
  disabled?: boolean
  required?: boolean
}

export const PhoneInput = ({
  phoneInput,
  setPhoneInput,
  disabled = false,
  required = false
}: Params) => {
  const onChangePhone = (phoneNumber: PhoneNumber) => {
    setPhoneInput(phoneNumber?.nationalNumber ?? '')
  }
  return (
    <PhoneInpuBuffet
      countryNameStrings={defaultCountryStrings}
      strings={defaultStrings}
      label='Phone'
      name='phone'
      value={{
        countryCode: '1',
        countryIsoCode: 'US',
        nationalNumber: phoneInput
      }}
      onChange={onChangePhone}
      disableCountry={true}
      disabled={disabled}
      required={required}
    />
  )
}
