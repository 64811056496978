import React, { useState } from 'react'
import { isValidEmail } from '@toasttab/email-validation'
import { isValidPhoneNumber } from '@local/strings'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { EmailInput } from '../../FormComponents/EmailInput'
import { PhoneInput } from '../../FormComponents/PhoneInput'
import { ReCaptcha } from '../../FormComponents/ReCaptcha'
import { useHistory } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'

export const LookupForm = () => {
  const { lookUp, isLookupLoading, resetSignup } = useLoyaltyContext()

  const { push } = useHistory()
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [captchaToken, setCaptchaToken] = useState()
  const [emailInput, setEmailInput] = useState('')
  const [phoneInput, setPhoneInput] = useState('')

  const handleSubmit = () => {
    lookUp({ email: emailInput, phone: phoneInput, token: captchaToken })
  }

  const isValid = () => {
    if (emailInput === '' && phoneInput === '') {
      return false
    }
    const validEmail = emailInput === '' || isValidEmail(emailInput)
    const validPhone = phoneInput === '' || isValidPhoneNumber(phoneInput)
    return validEmail && validPhone
  }

  return (
    <div className='max-w-lg p-4 space-y-4 rounded-lg'>
      <EmailInput
        emailInput={emailInput}
        setEmailInput={setEmailInput}
        disabled={phoneInput !== ''}
      />
      <div className='flex justify-center'>or</div>
      <PhoneInput
        phoneInput={phoneInput}
        setPhoneInput={setPhoneInput}
        disabled={emailInput !== ''}
      />
      <ReCaptcha
        setCaptchaVerified={setCaptchaVerified}
        setCaptchaToken={setCaptchaToken}
      />
      <Button
        disabled={!isValid() || !captchaVerified || isLookupLoading}
        onClick={handleSubmit}
        className='mt-4 w-full'
      >
        Look up
      </Button>
      <Button
        onClick={() => {
          resetSignup()
          push('rewardsSignup')
        }}
        variant='link'
        className='mt-4 w-full text-link flex justify-center'
      >
        Join as a new member
      </Button>
    </div>
  )
}
